import { theme } from "../../theme/theme";
import React from "react";
import styled from "styled-components";

const Burger = styled.button`
  position: fixed;
  top: ${theme.space[16]};
  right: ${theme.space[8]};
  appearance: none;
  background: transparent;
  border: 0;
  z-index: 10;
  width: 33px;
  height: 33px;

  @media (min-width: 768px) {
    display: none;
  }

  span {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 33px;
    height: 4px;
    position: relative;

    background: ${theme.colors.black};
    border-radius: 3px;

    z-index: 1;

    transform-origin: center center;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

    ${props => props.navigationOpen ? `opacity: 1;
    transform: rotate(45deg);` : '' }
  }

  span:nth-child(1) {
    /* transform: translateY(-10px); */
    ${props => props.navigationOpen ? `transform:  translateY(4px) rotate(45deg)` : 'transform: translateY(-6px)' }
  }

  span:nth-child(2) {
    /* transform: translateY(-6px); */
    ${props => props.navigationOpen ? `opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);` : '' }
  }

  span:nth-child(3)
  {
    /* transform: translateY(6px); */
    ${props => props.navigationOpen ? `transform: translateY(-4px) rotate(-45deg) ` : 'transform: translateY(6px)' }

  }
`;

export const Hamburger = ({ navigationOpen, setNavigationOpen }) => {
  return (
    <Burger onClick={() => setNavigationOpen(!navigationOpen) } navigationOpen={navigationOpen}>
      <span></span>
      <span></span>
      <span></span>
    </Burger>
  );
};
