import { normalize } from 'polished';
import { createGlobalStyle } from 'styled-components';
import { theme } from './theme';
import { typography } from './typography';

export const GlobalStyle = createGlobalStyle`
  ${normalize()}

  body {
    font-family: ${theme.fonts.body};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.8;
    min-width: 320px;
    background-color: ${theme.colors.white};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${theme.fonts.heading};
    font-weight: ${typography.fontWeights.normal};
    margin-top: 0;
    margin-bottom: 0;
  }

  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 3rem;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  p {
    margin-top: 0;
    line-height: 1.6;
  }
`;
