import React, { useState } from 'react'
import { Link } from 'gatsby'
import logo from '../img/wildwood-logo.png'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-awesome-styled-grid'
import { theme } from '../theme'
import { Hamburger } from './atoms/Hamburger'

const Nav = styled.nav`
  display: flex;
  margin: 0 auto;
  width: 100%;
`

const NavbarItem = styled(props => <Link {...props} />)`
  display: flex;
  align-items: center;
  padding: 1rem;
  color: ${theme.colors.black};
  text-decoration: none;
  justify-content: center;

  &:hover {
    text-decoration: underline;
  }

  @media (min-width: 768px) {

  }
`

const NavGroup = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${theme.colors.white};
  width: 100%;
  height: 100%;
  z-index: 1;
  align-items: center;
  justify-content: center;
  transform: ${props => props.navigationOpen ? 'translateY(0)' : 'translateY(-100%)'};
  transition: 300ms transform;

  @media (min-width: 768px) {
    flex-direction: row;
    transform: translateY(0);
    position: static;
    justify-content: flex-end;
  }
`

const Navbar = () => {
  const [navigationOpen, setNavigationOpen] = useState();

  return (
    <Container>
      <Row>
        <Col>
          <Nav
            role="navigation"
            aria-label="main-navigation"
          >
            <NavbarItem to="/" style={{marginRight: 'auto'}} title="Home">
              <img src={logo} alt="Wildwood logo" style={{ width: '7rem', height: 'auto' }} />
            </NavbarItem>

            <Hamburger setNavigationOpen={setNavigationOpen} navigationOpen={navigationOpen} />

            <NavGroup navigationOpen={navigationOpen}>
              <NavbarItem to="/de-bomen">
                De Bomen
              </NavbarItem>
              <NavbarItem to="/exclusief-en-stijlvol">
                Exclusief & stijlvol
              </NavbarItem>
              <NavbarItem to="/duurzaam-en-sociaal">
                Duurzaam & Sociaal
              </NavbarItem>
              <NavbarItem to="/over-ons">
                Over ons
              </NavbarItem>
              <NavbarItem to="/contact">
                Contact
              </NavbarItem>
            </NavGroup>
          </Nav>
        </Col>
      </Row>
    </Container>
  )

}

export default Navbar
