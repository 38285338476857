import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../components/Navbar";
import "./all.sass";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";

import { Container, Row } from "react-awesome-styled-grid";
import { GlobalStyle } from "../theme/GlobalStyle";
import { theme } from "../theme/theme";
import { ThemeProvider } from "styled-components";

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.png`}
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Navbar />
        <Container>
          <Row style={{paddingBottom: theme.space[20]}}>
            {children}
          </Row>
        </Container>
        {/* <Footer /> */}
      </ThemeProvider>
    </>
  );
};

export default TemplateWrapper;
